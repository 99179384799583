<template lang="pug">
v-card.pa-4
  TableV2(
    :headers="headers"
    :items="items.results || []"
    :pageCount="items.count"
    :currentPage="items.current"
    :querySearch="filter => getSingFailedDocuments(filter)"
    :actions="actions"
    :isLoading="isLoading")
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { deleteConfirmation } from '@/mixins/main'
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      headers: [
        { value: 'sailor.id', text: this.$t('tableHeaders.sailor_id'), sortable: false },
        { value: 'sailorFullName', text: this.$t('sailor'), sortable: false },
        { value: 'content_type', text: this.$t('typeDoc'), sortable: false },
        { value: 'unsigned_reason', text: this.$t('unsignedReason'), sortable: false },
        { value: 'event', sortable: false }
      ],
      actions: [
        {
          id: 1,
          tooltip: 'delete',
          action: (item) => this.deleteFailedRecord(item.id),
          color: 'error',
          name: 'mdi-trash-can-outline',
          isView: (item) => item.id
        },
        {
          id: 2,
          tooltip: 'tooltip.go',
          to: (item) => ({ name: item.link, params: { id: item.sailor.id, documentID: item.object_id } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          isView: (item) => item.sailor?.id && item.object_id
        }
      ]
    }
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'signFailedDocuments', access: checkAccess('menuItem-signFailedDocuments') })
  },
  computed: {
    ...mapState({
      items: state => state.backoffice.signFailedDocument,
      isLoading: state => state.backoffice.isLoading
    })
  },
  methods: {
    ...mapActions(['getSingFailedDocuments', 'removeSingFailedDocument']),

    async deleteFailedRecord (id) {
      const confirmation = await deleteConfirmation()
      if (confirmation) {
        const response = await this.removeSingFailedDocument(id)
        if (response?.code === 204) this.$notification.success('deletedDocument')
      }
    }
  }
}
</script>
